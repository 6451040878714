module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if(record.formComment) { ;
__p += '\n    <div class="form-comment">' +
((__t = ( record.formComment )) == null ? '' : __t) +
'</div>\n';
 } ;
__p += '\n<div class="innerRecord row">\n</div>    \n';

}
return __p
}