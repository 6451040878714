module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if(fieldState.isLabelVisible()) { ;
__p += '\n    <label for="' +
__e( fieldState.getInternal() ) +
'-' +
__e( fieldState.getId() ) +
'">' +
__e( fieldState.getLabel() ) +
'' +
__e( fieldState.isRequired(displayMode)?'*':'' ) +
'</label>\n';
 } ;
__p += '\n';
 if(!fieldState.isEnabled() || fieldState.isReadOnly()) { ;
__p += '\n    <div rows="4" cols="50" class="markdown-area" data-id="' +
__e( fieldState.getId() ) +
'">' +
((__t = ( markdownValue )) == null ? '' : __t) +
'</div>\n';
 } else { ;
__p += '\n    <textarea id="' +
__e( fieldState.getInternal() ) +
'-' +
__e( fieldState.getId() ) +
'" rows="4" cols="50" class="form-control changeableField" data-id="' +
__e( fieldState.getId() ) +
' ">' +
__e( fieldState.getValue() ) +
'</textarea>\n';
 } ;
__p += '\n</div>\n<span class="help-icon" style="display: none;" data-toggle="tooltip" data-html="true"><img src="/images/help-icon.png"></span>';

}
return __p
}