module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if(family.comment) { ;
__p += '\n    <div class="panel-comment">' +
((__t = ( family.comment )) == null ? '' : __t) +
'</div>\n';
 } ;
__p += '\n<div class="members-list row">\n\n</div>';

}
return __p
}