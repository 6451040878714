'use strict';

import { PortalView } from '../PortalView';
import { Context } from '@sai/protocol';
import RequestView from './RequestView';
import I18NUtils from '../../utils/I18NUtils';
import RecordView from '../record/Record';
const moment = require("moment");

export default class InitialRequestsView extends PortalView {
    private requests: Array<RequestView>;
    private maxCount: number;
    private addMoreElement: JQuery<HTMLElement>;
    private memberListEl: JQuery<HTMLElement>;
    private notEnoughRequests: JQuery<HTMLElement>;
    private lang: string;
    private comment: string;
    private formComment: string;

    constructor(options: { maxCount: number, [key: string]: any}) {
        super(options);
        this.requests = [];
        this.maxCount = options.maxCount;
        this.template = require('../../templates/initialRequests/InitialRequestList.ejs');
        this.lang = options.lang;
        this.comment = options.comment;
        this.formComment = options.formComment;
    }

    public render(): void {
        this.$el.addClass('initial-request-list-view');
        this.$el.html(this.template({ family: this }));
        this.memberListEl = this.$el.children('.initial-request-list');
        {
            this.addMoreElement = $('<div></div>').appendTo(this.memberListEl);
            let fakeMember = new RequestView({ el: this.addMoreElement, label: 'Nouveau', allowRemoval: false, values: null, lang:this.lang });
            fakeMember.render();
            this.addMoreElement.addClass('add-request');
            this.addMoreElement.on('click', () => {
                this.trigger('requests.newrequest');
            })
        }
        {
            this.notEnoughRequests = $('<div></div>').appendTo(this.$el);
            this.notEnoughRequests.addClass('validation-failure');
            let errorLabel = I18NUtils.getText('missing_initial_request',this.lang, {});
            errorLabel = errorLabel.replace('%d', ''+(this.maxCount === -1 ? 1 : this.maxCount));
            this.notEnoughRequests.text(errorLabel);
            this.notEnoughRequests.hide();
        }
    }

    public getRequests(): Array<RequestView> {
        return this.requests;
    }

    private buildLabel(values:any, validationTitle?:string) : string {
        if(validationTitle) {
            return validationTitle;
        } else {
            const startDate = values.FM_PAGE_START_DATE;
            var label = '';
            label += I18NUtils.getText('initial_request_from',this.lang, {});
            label += new moment(startDate).format('DD.MM.YYYY');
            label += ' : ';
            label += this.countSelectedSlots(values);
            label += I18NUtils.getText('initial_request_slots',this.lang, {});
            return label;
        }
    }

    private countSelectedSlots(values: any) : string {
        var nbHorraires = 0;
        var nbSlots = 0;
        var nbModules = 0;
        Object.keys(values)
            .forEach((key) => {
                var m = key.match(/^(MON|TUE|WED|THU|FRI)_H\d/);
                if(m && m.length > 0) {
                    var val = values[key];
                    var isModule = key.endsWith('_MODULE');
                    var isSlot = key.endsWith('_SLOT');
                    if(isSlot && val === '1') {
                        nbSlots++;
                    } else if(isModule && val === '1') {
                        nbModules++;
                    } else if(!isModule && !isSlot && values[key].length > 0) {
                        nbHorraires++;
                    }
                }
            });
        if(nbModules > 0) {
            return nbModules.toString();
        } else if(nbSlots > 0) {
            return nbSlots.toString();
        } else {
            return nbHorraires.toString();
        }
    }

    public createNewRequest(values: typeof Context, recordView:RecordView, validationTitle?: string) {
        let strVals = this.getFieldStrValues(values);
        let label = this.buildLabel(strVals, validationTitle);
        let newRequestEl = $('<div></div>').insertBefore(this.addMoreElement);
        let newRequest = new RequestView({
            el: newRequestEl,
            values: values,
            label: label,
            allowRemoval: true,
            lang: this.lang
        });
        if(validationTitle) { newRequest.setValidationTitle(validationTitle); }
        this.addRequest(newRequest);
        this.addEventsListeners(newRequestEl, newRequest, recordView);
    }

    private getFieldStrValues(values: typeof Context): {[key:string]: string} {
        var fields = values.attributes.screen;
        let strVals = {};
        for (var key in fields) {
            strVals[fields[key].key] = fields[key].value;
        }
        return strVals;
    }

    public updateRequest(id: number, values: typeof Context, recordView:RecordView, validationTitle?:string): void {
        for (let key = this.requests.length - 1; key >= 0; key--) {
            let request = this.requests[key];
            if (request.getId() === id) {
                request.setValues(values);
                request.setValidationTitle(validationTitle);
                request.setLabel(this.buildLabel(this.getFieldStrValues(values), validationTitle));
                request.render();
                this.addEventsListeners(request.$el, request, recordView);
                break;
            }
        }
    }

    private addEventsListeners(element, request:RequestView, recordView:RecordView):void {
        element.on('click', () => {
            this.trigger('requests.modifyrequest', request, recordView);
        });
        element.find('.remove-request').on('click', () => {
            console.info('remove');
            this.deleteRequest(request.getId());
            this.trigger('requests.removedrequest', request.getId(), recordView);
        });
    }

    /**
     * Removes the requested member from the family
     */
    public deleteRequest(id: number): void {
        for(let i in this.requests) {
            let member = this.requests[i];
            let memberIndex:number = parseInt(i);
            if(member.getId() === id) {
                this.requests[memberIndex].$el.remove();
                this.requests.splice(memberIndex, 1);
            }
        }
        if(this.requests.length < this.maxCount || this.maxCount === -1) {
            this.displayAddMore();
        }
    }

    public addRequest(newRequest: RequestView): void{
        this.requests.push(newRequest);
        newRequest.render();
        if(this.maxCount === this.requests.length) {
            this.hideAddMore();
        }
    }

    public getRequest(id: number): RequestView {
        for (let key = this.requests.length - 1; key >= 0; key--) {
            let member = this.requests[key];
            if (member.getId() === id) {
                return member;
            }
        }
    }

    public displayAddMore(): void {
        this.addMoreElement.show();
    }

    public hideAddMore(): void {
        this.addMoreElement.hide();
    }

    public hasEnoughRequets(): boolean {
        return (this.requests.length >= 1 && this.maxCount == -1) || this.maxCount === this.requests.length;
    }

    /**
     * Returns true if the required conditions for family content are matched.
     * Displays an alert message if it's not the case.
     */
    public validate() : boolean {
        this.notEnoughRequests.hide();
        //The show is performed only if there is not enough members
        let isValid = this.hasEnoughRequets();
        if(!isValid) {
            this.notEnoughRequests.show();
        }
        return isValid;
    }

    public deleteAllRequests() {
        this.$el.find('.remove-request').trigger('click');
    }

    public getFormComment() {
        return this.formComment
    }
}
