'use strict';

import FieldView from '../Field';
// https://gist.github.com/rxaviers/7360908
import { full as emoji } from 'markdown-it-emoji'
var md = require('markdown-it')().use(emoji);

class TextAreaField extends FieldView {

    onDOMUpdated () {
        super.onDOMUpdated();
        //Resizing textarea so that it fills its content
        this.$el.css({
            'height': 'auto',
            'margin-bottom': '0.8rem'
        });
    }

    getFocusableElement(){
        return this.$el.find('textarea');
    }

    getFieldElement(){
        return this.$el.find('textarea');
    }

    getMarkdownValue() {
        return md.render(this.fieldState.getValue());
    }

    getRenderingModel() {
        var rm = super.getRenderingModel();
        rm.markdownValue = this.getMarkdownValue();
        return rm;
    }
}

export default TextAreaField;
