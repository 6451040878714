import StringUtils from '../utils/StringUtils';
import * as _ from 'lodash';

class LoadingMask {
    private template: any;
    private innerEl: JQuery<HTMLElement>;
    private static _instance: LoadingMask;

    private requestAlreadyCancelled: { [key: string]: boolean };
    private requestStack: { [key: string]: string };
    private pendingAuthRequest: { [key: string]: boolean };
    private displayDelayMs: number;

    public static get Instance(): LoadingMask {
        return this._instance || (this._instance = new this());
    }

    private constructor() {
        this.displayDelayMs = 50;
        this.requestStack = {};
        this.requestAlreadyCancelled = {};
    }

    private initialize() {
        this.innerEl = $('#appMask');
    }

    public requestLoading(text?: string): Promise<string> {
        let me = this;
        return new Promise((resolve, reject) => {
            if (!this.innerEl) { this.initialize(); }
            let reqId = StringUtils.guid();
            if (Object.keys(this.requestStack).length === 0) {
                //Loading hasn't been requested yet
                me.requestAlreadyCancelled[reqId] = false;
                setTimeout(() => {
                    if (!me.requestAlreadyCancelled[reqId]) {
                        me.requestStack[reqId] = text;
                        me.show(text);
                    } else {
                        delete me.requestAlreadyCancelled[reqId];
                    }
                }, this.displayDelayMs);
            } else {
                me.requestStack[reqId] = text;
                //Loading has already been requested
                //thus we only update the displayed text
                this.updateText(text);
            }
            resolve(reqId);
        });
    }

    private show(text?: string): void {
        this.updateText(text);
        this.innerEl.removeClass('loading-mask-hidden');
    }

    private updateText(text?: string): void {
        this.innerEl.find('.loading-text').text(text || 'Chargement en cours');
    }

    public hide(reqId: string, force?: boolean): void {
        if (reqId === undefined && force === undefined) {
            throw new Error('invalid loading screen definition');
        }
        if (!this.innerEl) { this.initialize(); }
        delete this.requestStack[reqId];
        if (this.requestAlreadyCancelled[reqId] !== undefined) {
            this.requestAlreadyCancelled[reqId] = true;
        }
        let remainingKeys = Object.keys(this.requestStack);
        if (remainingKeys.length === 0 || force) {
            this.innerEl.addClass('loading-mask-hidden');
        } else {
            this.updateText(this.requestStack[remainingKeys[0]]);
        }
        if (force) {
            this.pendingAuthRequest = Object.assign({}, this.requestAlreadyCancelled);

            for (let i in this.requestAlreadyCancelled) {
                this.requestAlreadyCancelled[i] = true;
            }
        }
    }

    public restackPending(): void {
        this.requestAlreadyCancelled = Object.assign(this.requestAlreadyCancelled, this.pendingAuthRequest);
        let remainingKeys = Object.keys(this.requestStack);
        if (remainingKeys[0] !== undefined) {
            this.show(this.requestStack[remainingKeys[0]]);
        }
    }
}

const singleInstance = LoadingMask.Instance;

export default singleInstance;